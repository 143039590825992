<app-header></app-header>
<h1>Irish news archive on this date</h1>

<!-- <ul>
    <li *ngFor="let item of archived_dates_list">
        <a  routerLink="/archive/{{item}}">
            {{ item }}
         </a>
    </li>
</ul> -->

<mat-list>
    <mat-list-item *ngFor="let item of archived_dates_list"> 
        <a  routerLink="/archive/{{item}}"> <!--(click)="getStoriesForDate(item)"-->
            {{ item }}
         </a>
    </mat-list-item>
</mat-list>
<app-footer></app-footer>


