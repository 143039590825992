import { ApiService } from './../ApiService';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NewsItem } from '../NewsItem';
import {ActivatedRoute} from "@angular/router";

@Component({
    moduleId: module.id,
    selector: 'archive-date',
    templateUrl: 'archive-date.component.html',
    styleUrls: ['archive-date.component.css']
})
export class ArchiveDateComponent implements OnInit, OnDestroy{

    constructor(private apiService: ApiService, private route: ActivatedRoute){}
    date =""    
    this_dates_data: NewsItem[] = []

    isLoading=true

    ngOnInit() {
        console.log('archive-date ngOnInit')

        this.route.params.subscribe(x => {

            console.log(x)
            this.date=x['date']
            this.getStoriesForDate(x['date'])
        })

    }
    ngOnDestroy(): void {
        throw new Error('Method not implemented.');
    }


    getStoriesForDate(date:string){
        console.log('archive-date getStoriesForDate ' + date)
        this.apiService.getArchiveForDate(date).subscribe(
            data => {
                if (data) {
                    this.isLoading=false
                }
                console.log('got data');
                console.log(data)
                this.this_dates_data=data;
            }
        )
        
    }

}
