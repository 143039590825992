
import { ArchiveDateComponent } from './archive-date/archive-date.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { AboutComponent } from './about/about.component';
import { Main2Component } from './main2/main2.component';
import { HomeComponent } from './home/home.component';
import { ArchiveComponent } from './archive/archive.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
{ 
  path: '', component: Main2Component,
  children:[
  { path: '', component: HomeComponent, },

  ]
  }, 
  { path: 'archive', component: ArchiveComponent },
  { path: 'archive/:date', component: ArchiveDateComponent },
  { path: 'about', component: AboutComponent, },
  { path: 'privacy', component: PrivacyComponent, },
  // otherwise redirect to home
  { 
    path: '**', redirectTo: 'Main2Component' 
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
