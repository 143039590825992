<app-header></app-header>
<div class="about-container">
    <h1>About newsjunky.ie</h1>
    <p> newsjunky.ie is a new independent news website targeted at Irish news junkies. newsjunky.ie’s mission is to give users the most relevant, most timely and most complete news-discovery platform in Ireland. Users look to newsjunky.ie to discover the most important, most discussed stories in Ireland on a minute-to-minute basis. We also offer a live feed of ALL the nation’s news so you will never miss a story again; and archive all this data so users can peruse news stories and associated social media data from previous days.
    </p>
    <p>
    We use social media data to determine the most popular stories in the Irish news sphere right now. newsjunky.ie continuously scans social media activity on news stories, determining which stories are generating the most activity at the current moment. In this way, we offer users a unique up-to-the-minute view of the news which no other news outlet can offer.
    </p>   
    <p>
    newsjunky.ie only publishes stories from reputable Irish news sources, and links back to those publications and their twitter feeds. We list the top stories being shared on social media, linking both to the original story and to the tweet containing that story. 
    </p>
    
    <p>
    <a href="https://newsjunky.ie">https://newsjunky.ie</a>
    </p>
    
</div>
<app-footer></app-footer>


