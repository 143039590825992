import { Tweet } from './Tweet';
import { NewsItem } from './NewsItem';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from './../environments/environment';

// const apiUrl = environment.apiUrl;
@Injectable({
    providedIn: 'root'
})

export class ApiService {
    constructor(private httpClient: HttpClient, private router: Router) { }

    // getTopHeadlines(){
    //     return this.httpClient.get<[NewsItem]>(apiUrl + '/topheadlines')
    // }
       
    // getTopTwitter(){
    //     return this.httpClient.get<[Tweet]>(apiUrl + '/toptwitter')
    // }

    getArchivedDates(){
        return this.httpClient.get<[string]>('https://ws.newsjunky.ie/archived_dates')
    }

    getArchiveForDate(date:string){
        return this.httpClient.get<[NewsItem]>('https://ws.newsjunky.ie/archive/'+date)
    }



}