import { Component } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'main2',
    templateUrl: 'main2.component.html',
    styleUrls: ['main2.component.scss']
})
export class Main2Component {

}
