<script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script> 



<div *ngIf="isLoading"  style = "height:100%; margin-left:45%; margin-top:100px">
    <span >Loading news...</span>
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>
<div class="news-container" *ngIf="!isLoading">
    <mat-tab-group (selectedTabChange)="onChange($event)">
        <mat-tab label="Top Stories"> 
            <h1 style="font-size:small">Top news stories in Ireland </h1>
            <table class="table table-striped table-bordered">
                <thead>
                    <tr>  
                        <th matTooltip="interest trend over time (max 1 hr)"> <div class = "trend">Trend</div></th>
                        <th>Time</th>
                        <th>Headline</th>
                        <th>Description</th>
                        <th>Source</th>
                        <th matTooltip="tweet link / likes / shares">Twitter</th>       
                    </tr>
                </thead>
                <tbody>
                    <tr [@rowsAnimation]="" *ngFor="let news of top_stories">
                        <td>
                            <div class="trend">
                                <ngx-trend [data]="news.spark_line_data"></ngx-trend> 
                            </div>
                        </td>
                        <td>
                            <span class="date"> {{news.date_local}} </span>  <!--{{timeAgo(news.date)}-->
                        </td>
                        
                        <td>
                            <a href = "{{ news.url }}" target="_blank">
                                {{ news.headline }}
                            </a>
                        </td> 
                        <td>{{news.description}}</td>
                        <td>{{news.source}}</td>
                        <td>
                            <span>
                            <a href = "{{ news.tweet_url }}" target="_blank">
                                <span class="iconify" data-icon="mdi-twitter" data-inline="false"></span>
                            </a> /  
                            {{ news.total_likes }} / {{ news.total_shares }} 
                        </span>
                        </td>
                        <!-- <td>{{news.datetime}}</td> -->
                        <!-- <td>{{news.url}}</td> -->
                    </tr>
                </tbody>
            </table>            
        </mat-tab>
        <mat-tab label="Live Newsfeed">
            <h1 style="font-size:small">Live newsfeed Ireland:</h1>
   
            <table class="table table-striped table-bordered">
                <thead>
                    <tr>  
                        <th>Time</th>
                        <th>Headline</th>
                        <th>Description</th>
                        <th>Source</th>
                        <th>Twitter</th>
        
                    </tr>
                </thead>
                <tbody>
                    <tr [@rowsAnimation]=""  *ngFor="let news of new_story_list">
                        <td>
                            <span class="date"> {{news.date_local}} </span>  <!--{{timeAgo(news.date)}-->
                        </td>
                        <td>
                            <a href = "{{ news.url }}" target="_blank">
                                {{ news.headline }}
                            </a>
                        </td> 
                        <td>{{news.description}}</td>
                        <td>{{news.source}}</td>
                        <td>
                            <a href = "{{ news.tweet_url }}" target="_blank">
                                <span class="iconify" data-icon="mdi-twitter" data-inline="false"></span>
                            </a>
                            
                        </td> 
                    </tr>
                </tbody>
            </table>
        </mat-tab>

        <mat-tab label="Top Tweets"> 
            <blockquote class="twitter-tweet" *ngFor="let tweet of top_tweets_list"><a href="https://twitter.com/x/status/{{ tweet[0] }}"></a></blockquote> 
            
        </mat-tab>      
    </mat-tab-group>
</div>     
<!-- <blockquote class="twitter-tweet"><a href="https://twitter.com/x/status/807811447862468608"></a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script> -->


<!-- 
<div class="twitter-container">
    <h3>Top twitter</h3>
    <tr *ngFor="let tweet of currentTweets">
        <td> -->
            <!-- have to add the styling here because this content is generated dynamically, after css file is read--> 
            <!-- <div [innerHtml] = modifyTweetForDisplay(tweet.embed_html)
                [ngStyle]="styleTweet()">  
            </div>      
                
        </td>    
    </tr>
    
</div> -->

