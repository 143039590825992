import { rowsAnimation } from './../tableRowAnimation';
import { websocketService } from './../websocketService';

import { NewsItem } from './../NewsItem';
import { ApiService } from './../ApiService';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';


@Component({
    moduleId: module.id,
    selector: 'home',
    templateUrl: 'home.component.html',
    styleUrls: ['home.component.css'],
    animations: [rowsAnimation],
})
export class HomeComponent implements OnInit, OnDestroy{

    constructor(private apiService: ApiService,
        private websocketService: websocketService){}


    top_stories=[]
    new_story_list=[]
    top_tweets_list=[]
    isLoading=true

    ngOnDestroy(){}
    ngOnInit(){
        this.getData();
    }

    ngAfterViewInit(): void {

   
      (<any>window).twttr.widgets.load();
    }
    
    sleep1(){
        return new Promise( resolve => setTimeout(resolve, 1000) );
    }

    onChange(event: MatTabChangeEvent) {
        const tab = event.tab.textLabel;
        console.log(tab);
        if(tab==="Top Tweets")
         {
           console.log("Top Tweets clicked");
           this.reload_twitter_js()
          }
      }

    reload_twitter_js(){
        console.log("reload_twitter_js ")
        console.log("go to sleep...")
        this.sleep1();

        (<any>window).twttr.widgets.load();
        console.log("back from sleep and called twttr widgets load")
    }

    getData(){
        var s = this.websocketService.connect();
        s.subscribe((data : any)=>{
            try{
                if (data) {
                    this.isLoading=false
                }
                console.dir(data)
                if (data.top_stories){
                    let now = new Date()
                    console.log("top_stories notification " + now.toLocaleString() )
                    var payload = JSON.parse(data.top_stories);
                    var top_stories:NewsItem[] = <NewsItem[]> payload;

                    // for (var n of top_stories){
                    //     n.spark_line_data = [34,32,5,36,76]
                    // }
                    console.dir(top_stories);
                    this.top_stories = top_stories
                    
                } else if (data.new_story){
                    console.log('new_story notification')
                    var payload = JSON.parse(data.new_story);
                    var n: NewsItem = <NewsItem>payload;
                    
                    console.dir(n);
                    this.new_story_list.pop(); //removes from the left
                    this.new_story_list.unshift(n);
                } else if (data.new_story_list) {
                    console.log('new_story_list notification')
                    var payload = JSON.parse(data.new_story_list);

                    var new_story_list:NewsItem[] = <NewsItem[]> payload;

                    console.dir(new_story_list);
                    //reverse the list; they;re in the wrong order for displaying

                    this.new_story_list = new_story_list.reverse();
                } else if (data.top_tweets){
                    console.log('top_tweets_list notification')
                    var payload = JSON.parse(data.top_tweets);
                    console.dir(payload);
                    this.top_tweets_list = payload
                    
                }
            }
            catch (e){
                console.log(e.message);
            }           
        })
       

        // this.apiService.getTopHeadlines().subscribe(newsItems => {   //jcdebug revert websocket test
        //     this.currentNewsItems = newsItems;
        //     // console.log(newsItems[0].headline)
        // }, error => {
        //     console.log(error);
        //     // this.signupErrorListener.next(error);
        // }
        // );

        // this.apiService.getTopTwitter().subscribe(tweets => {
        //     this.currentTweets = tweets;
        //     // console.log(this.currentTweets[0].url)
        //     // console.log(this.currentTweets[0].embed_html)
        // }, error => {
        //     console.log(error);
        //     // this.signupErrorListener.next(error);
        // }
        // );
    }

    timeAgo(then){
   
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
            ];
        const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        let now: Date = new Date();  
      
        let thenDate: Date = new Date(then)
    
        var delta = now.valueOf() - (thenDate.valueOf()) 
        // console.log(deltaTime.getUTCMinutes());
        var deltaMinutes = Math.ceil(delta/(1000*60))

        var hours = -1;
        
        if (deltaMinutes > 120){
            hours = Math.floor(deltaMinutes / 60);
        }
        
        if (hours > 48){
            return (dayNames[thenDate.getDay()] + ' ' +thenDate.getDate() + ' ' + monthNames[thenDate.getMonth()]);
        } else if (hours > 2) {
            return hours + " hours ago";
        } else {
            return deltaMinutes + " minutes ago";
        }
            
    }

    styleTweet() {
        var style = {"border": "1px solid black", "padding": "5px"};
        return style;
    }
    modifyTweetForDisplay(html){
        let h=html.replaceAll("<br>", "");
        return h;
    }
}
