import { rowsAnimation } from '../tableRowAnimation';
import { NewsItem } from '../NewsItem';
import { ApiService } from '../ApiService';
import { Component, OnInit, OnDestroy } from '@angular/core';


@Component({
    moduleId: module.id,
    selector: 'archive',
    templateUrl: 'archive.component.html',
    styleUrls: ['archive.component.css'],
    animations: [rowsAnimation],
})
export class ArchiveComponent implements OnInit, OnDestroy{
    
    constructor(private apiService: ApiService){}
    archived_dates_list=[]
    this_dates_data: NewsItem[] = []
    
    ngOnInit(): void {
        this.getArchivedDates();
    } 

    ngOnDestroy(): void {}

    

    getArchivedDates(){
        this.apiService.getArchivedDates().subscribe(dates => {  
                this.archived_dates_list = dates;
            }, error => {
                console.log(error);
            }
        );           
    }

   

}
