import { Injectable } from '@angular/core';
import * as socketio from 'socket.io-client';
import { Observable } from 'rxjs';
import * as Rx from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class websocketService {

  // Our socket connection
  private socket: socketio.Socket;

  constructor() { }

  connect(): Rx.Subject<MessageEvent> {
    // If you aren't familiar with environment variables then
    // you can hard code `environment.ws_url` as `http://localhost:5000`
    console.log('connecting socket...');
    this.socket = socketio.io(environment.ws_url);
    // We define our observable which will observe any incoming messages
    // from our socket.io server.
    let observable = new Observable(observer => {
        this.socket.on('message', (data) => {
          if (data.top_stories){
            observer.next({"top_stories": data.top_stories});
          } else if (data.new_story){
            observer.next({"new_story": data.new_story});
          } else if (data.new_story_list){ 
            observer.next({"new_story_list": data.new_story_list});           
          } else if (data.top_tweets){ 
            observer.next({"top_tweets": data.top_tweets});           
          }
        })
        return () => {
          this.socket.disconnect();
        }
    });

    // We define our Observer which will listen to messages
    // from our other components and send messages back to our
    // socket server whenever the `next()` method is called.
    let observer = {
        next: (data: Object) => {
            this.socket.emit('message', JSON.stringify(data));
        },
    };

    // we return our Rx.Subject which is a combination
    // of both an observer and observable.
    return Rx.Subject.create(observer, observable);
  }

}