<app-header></app-header>
<!-- <a href = "http://newsjunky.ie"> Home </a><br/> -->

<h1 >All news in Ireland on {{date}}:</h1>

<div *ngIf="isLoading"  style = "height:100%; margin-left:45%; margin-top:100px">
    <span >Loading news...</span>
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>

<div class="news-container" *ngIf="!isLoading">
    <table class="table table-striped table-bordered">
        <thead>
            <tr>  
                <th>Time</th>
                <th>Headline</th>
                <th>Description</th>
                <th>Source</th>
                <th>Twitter</th>       
            </tr>
        </thead>
        <tbody>
            <tr class = "{{news.status}}" *ngFor="let news of this_dates_data">
                
                <td>
                    <span class="date"> {{news.date_local}} </span>  <!--{{timeAgo(news.date)}-->
                </td>
                
                <td>
                    <a href = "{{ news.url }}" target="_blank">
                        {{ news.headline }}
                    </a>
                </td> 
                <td>{{news.description}}</td>
                <td>{{news.source}}</td>
                <td>
                    <span>
                    <a href = "{{ news.tweet_url }}" target="_blank">
                        <span class="iconify" data-icon="mdi-twitter" data-inline="false"></span>
                    </a> /  
                    {{ news.total_likes }} / {{ news.total_shares }} 
                </span>
                </td>
                <!-- <td>{{news.datetime}}</td> -->
                <!-- <td>{{news.url}}</td> -->
            </tr>
        </tbody>
    </table>

</div> 
<app-footer></app-footer>