<p>
NewsJunky.ie is an independent Irish news-generating/aggregating website. This privacy policy will explain how our organization uses the personal data we collect from you when you use our website.
</p>

<p>Last updated: 6 January 2022</p>

<h2>
Topics:
</h2>

<ul>
<li> What data do we collect?</li>
<li>How do we collect your data?</li>
<li>How will we use your data?</li>
<li>How do we store your data?</li>
<li>Marketing</li>
<li>What are your data protection rights?</li>
<li>What are cookies?</li>
<li>How do we use cookies?</li>
<li>What types of cookies do we use?</li>
<li>How to manage your cookies</li>
<li>Privacy policies of other websites</li>
<li>Changes to our privacy policy</li>
<li>How to contact us</li>
<li>How to contact the appropriate authorities</li>
</ul>

<h3>What data do we collect? </h3>
<p>
Our Company collects the following data:
</p>
<p>
Cookie data
Third party vendors, including Google, use cookies to serve ads based on a user's prior visits to your website or other websites.
Google's use of advertising cookies enables it and its partners to serve ads to your users based on their visit to your sites and/or other sites on the Internet.
Users may opt out of personalized advertising by visiting Ads Settings. (Alternatively, you can direct users to opt out of a third-party vendor's use of cookies for personalized advertising by visiting www.aboutads.info.)
</p>

<h3>How do we collect your data?</h3>
<p>We collect data in the form of cookies, described below</p>

<h3>
How will we use your data?
</h3>
<p>
Our Company may share your data with Google Adsense for targetted ads.
</p>

<h3>How do we store your data?</h3>
<p> Our Company does not directly store your data. Your data is stored in cookies in your own browser.
</p>

<h4>Marketing</h4>
<p>
Our Company may like to send you information about products and services of ours that we think you might like, as well as those of our partner companies.
</p>
<p>
Google LLC, a subsidiary of Alphabet Inc.
If you have agreed to receive marketing, you may always opt out at a later date.
</p>
<p>
You have the right at any time to stop Our Company from contacting you for marketing purposes or giving your data to other members of the Our Company Group.
</p>
<p>
If you no longer wish to be contacted for marketing purposes, please click <a href = "mailto:unsubscribe_marketing@newsjunky.ie">here</a>.
</p>

<h3>What are your data protection rights?</h3>
<p>Our Company would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p> 
<ul>
The right to access – You have the right to request Our Company for copies of your personal data. We may charge you a small fee for this service.

<li>The right to rectification – You have the right to request that Our Company correct any information you believe is inaccurate. You also have the right to request Our Company to complete the information you believe is incomplete.</li>

<li>The right to erasure – You have the right to request that Our Company erase your personal data, under certain conditions.</li>

<li>The right to restrict processing – You have the right to request that Our Company restrict the processing of your personal data, under certain conditions.</li>

<li>The right to object to processing – You have the right to object to Our Company’s processing of your personal data, under certain conditions.</li>

<li>The right to data portability – You have the right to request that Our Company transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
</ul>
<p>
If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email:
</p>
<p>
Email us at: info@newsjunky.ie
</p>

<h3>Cookies</h3> 
<p> Cookies are text files placed on your computer to collect standard Internet log information and visitor behavior information. When you visit our websites, we may collect information from you automatically through cookies or similar technology
    For further information, visit allaboutcookies.org.
</p>


<h3>How do we use cookies?</h3>
<p> Our Company uses cookies in a range of ways to improve your experience on our website, including:
</p>

<h3>What types of cookies do we use?</h3>
<p> There are a number of different types of cookies, however, our website uses:</p>
<ul>
<li> Advertising – Our Company uses these cookies to collect information about your visit to our website, the content you viewed, the links you followed and information about your browser, device, and your IP address. Our Company sometimes shares some limited aspects of this data with third parties for advertising purposes. We may also share online data collected through cookies with our advertising partners. This means that when you visit another website, you may be shown advertising based on your browsing patterns on our website.</li>
</ul>

<h3>How to manage cookies</h3>
<p>
You can set your browser not to accept cookies, and the above website tells you how to remove cookies from your browser. However, in a few cases, some of our website features may not function as a result.
</p>

<h3>Privacy policies of other websites</h3>
<p> The Our Company website contains links to other websites. Our privacy policy applies only to our website, so if you click on a link to another website, you should read their privacy policy.
</p>

<h3>Changes to our privacy policy</h3>
<p>
Our Company keeps its privacy policy under regular review and places any updates on this web page. This privacy policy was last updated on 9 January 2019.
</p>

<h3>How to contact us</h3>
<p> If you have any questions about Our Company’s privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us.
</p>
<h3>
How to contact the appropriate authority
</h3>
<p>
Should you wish to report a complaint or if you feel that Our Company has not addressed your concern in a satisfactory manner, you may contact the Information Commissioner’s Office.

Email us at: info@newsjunky.ie
</p>
