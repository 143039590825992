<div class="toolbar-container" style = "text-align: center">	
    <a mat-button id = "home_link_full_screen" href = "https://newsjunky.ie">
        <img src="assets/newsjunky_logo.png" class = "logo_full_screen" alt="newsjunky.ie">
    </a>
    <!-- <a mat-button id = "home_link_small_screen" routerLink='/'><i  class="material-icons">home</i></a> -->
    <ul>
        <li>
            <button >
                <a mat-button href="/about"><i  class="material-icons" style="vertical-align: middle">help_outline</i> About</a>
            </button>
            <button >
                <a href="/archive"><i  class="material-icons" style="vertical-align: middle">storage</i> Archive</a>
            </button>
        </li>
    </ul>
</div>

